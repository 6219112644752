import {
  faCalendarXmark,
  faKey,
  faLock,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { ReactElement, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, ResourceIdentifier, Switch, Tag, TaskBar, ValueList } from '.';
import config from '../config';
import theme from '../exports.module.scss';
import { Token } from '../types';
import classNames from '../utilities/class-names';
import formatDate from '../utilities/format-date';
import './TokenCard.scss';

type TokenCardProps = {
  token: Token;
  admin?: boolean;
  handleShowSubscriptionLockedModal?: () => void;
  [key: string]: any;
};

export function TokenCard({
  token,
  admin,
  handleShowSubscriptionLockedModal,
  ...props
}: TokenCardProps): ReactElement {
  const expired = !!(
    token.expiresAt && dayjs(token.expiresAt).isBefore(dayjs())
  );

  const [subscriptionLockedButtonShake, setSubscriptionLockedButtonShake] =
    useState(true);

  const handleSubscriptionLockedTagClick = useCallback(() => {
    handleShowSubscriptionLockedModal?.();

    setSubscriptionLockedButtonShake(false);
    setTimeout(() => {
      setSubscriptionLockedButtonShake(true);
    }, 500);
  }, [handleShowSubscriptionLockedModal]);

  return (
    <Card
      className={classNames(
        'token-card',
        token.locked ? 'token-locked' : '',
        expired ? 'token-expired' : ''
      )}
      {...props}
    >
      <div className="corner-tags-container bottom">
        <Tag intent="danger" icon={faCalendarXmark} show={expired}>
          Expired
        </Tag>
        <Tag
          intent="warning"
          icon={faLock}
          show={token.locked}
          onClick={handleSubscriptionLockedTagClick}
          shake={subscriptionLockedButtonShake}
        >
          Locked
        </Tag>
      </div>
      <TaskBar
        left={
          <h2>
            <FontAwesomeIcon icon={faKey} />
            <Link to={`/tokens/${token.id}`}>
              {token.name || 'Unnamed token'}
            </Link>
          </h2>
        }
        right={
          <>
            <Switch
              name={`token-${token.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color={theme.intentSuccess}
              value={token.activated}
              readonly
            />
          </>
        }
      />
      <ValueList
        values={{
          Id: <code>{token.id}</code>,
          'Created by': admin ? (
            <ResourceIdentifier
              resourceType="user"
              resource={{
                id: token?.user?.id ?? '',
                displayName: token?.user?.displayName ?? '',
              }}
              copyable
            />
          ) : undefined,
          'Created at': token
            ? formatDate(token.createdAt, config.pageDateFormat)
            : '',
          'Expires at':
            token && token.expiresAt ? (
              formatDate(token.expiresAt, config.pageDateFormat)
            ) : (
              <span className="extra">Never</span>
            ),
        }}
      />
    </Card>
  );
}
