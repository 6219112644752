import loadable from '@loadable/component';
import { Elements } from '@stripe/react-stripe-js';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ProtectedRoute } from './components';
import { AuthProvider } from './contexts/auth';
import { BulletinProvider } from './contexts/bulletin';
import { DocumentationTokenProvider } from './contexts/documentation-token';
import DocumentationListSchemas from './routes/documentation/ListSchemas';
import stripe from './utilities/stripe';

// Main pages
const Action = loadable(() => import('./routes/Action'));
const CancelSubscription = loadable(
  () => import('./routes/CancelSubscription')
);
const Dashboard = loadable(() => import('./routes/Dashboard'));
const Event = loadable(() => import('./routes/Event'));
const Events = loadable(() => import('./routes/Events'));
const ForgotPassword = loadable(() => import('./routes/ForgotPassword'));
const Home = loadable(() => import('./routes/Home'));
const Identity = loadable(() => import('./routes/Identity'));
const IdentityEditor = loadable(() => import('./routes/IdentityEditor'));
const Identities = loadable(() => import('./routes/Identities'));
const Index = loadable(() => import('./routes/Index'));
const IndexEditor = loadable(() => import('./routes/IndexEditor'));
const Indexes = loadable(() => import('./routes/Indexes'));
const Item = loadable(() => import('./routes/Item'));
const ItemVersionCompare = loadable(
  () => import('./routes/ItemVersionCompare')
);
const ItemEditor = loadable(() => import('./routes/ItemEditor'));
const ItemVersions = loadable(() => import('./routes/ItemVersions'));
const Items = loadable(() => import('./routes/Items'));
const List = loadable(() => import('./routes/List'));
const ListEditor = loadable(() => import('./routes/ListEditor'));
const Lists = loadable(() => import('./routes/Lists'));
const Login = loadable(() => import('./routes/Login'));
const Payment = loadable(() => import('./routes/Payment'));
const Privacy = loadable(() => import('./routes/Privacy'));
const Profile = loadable(() => import('./routes/Profile'));
const ProfileEditor = loadable(() => import('./routes/ProfileEditor'));
const Register = loadable(() => import('./routes/Register'));
const ResetPassword = loadable(() => import('./routes/ResetPassword'));
const Settings = loadable(() => import('./routes/Settings'));
const ManageSubscription = loadable(
  () => import('./routes/ManageSubscription')
);
const Subscriptions = loadable(() => import('./routes/Subscriptions'));
const Subscription = loadable(() => import('./routes/Subscription'));
const Terms = loadable(() => import('./routes/Terms'));
const Test = loadable(() => import('./routes/Test'));
const Token = loadable(() => import('./routes/Token'));
const TokenEditor = loadable(() => import('./routes/TokenEditor'));
const Tokens = loadable(() => import('./routes/Tokens'));
const User = loadable(() => import('./routes/User'));
const UserEditor = loadable(() => import('./routes/UserEditor'));
const Users = loadable(() => import('./routes/Users'));
const UserDeleted = loadable(() => import('./routes/UserDeleted'));
const VerifyEmail = loadable(() => import('./routes/VerifyEmail'));

// Documentation
const DocumentationHome = loadable(() => import('./routes/documentation/Home'));
const DocumentationTokenPermissions = loadable(
  () => import('./routes/documentation/TokenPermissions')
);
const DocumentationEventLog = loadable(
  () => import('./routes/documentation/EventLog')
);
const DocumentationGettingStarted = loadable(
  () => import('./routes/documentation/GettingStarted')
);
const DocumentationTalkingToTheAPI = loadable(
  () => import('./routes/documentation/TalkingToTheAPI')
);
const DocumentationPathsAndPointers = loadable(
  () => import('./routes/documentation/PathsAndPointers')
);
const DocumentationRealtimeUpdates = loadable(
  () => import('./routes/documentation/RealtimeUpdates')
);
const DocumentationUsingTheSDKs = loadable(
  () => import('./routes/documentation/UsingTheSDKs')
);
const DocumentationVersionControl = loadable(
  () => import('./routes/documentation/VersionControl')
);
const DocumentationAPIReference = loadable(
  () => import('./routes/documentation/APIReference')
);
const DocumentationLists = loadable(
  () => import('./routes/documentation/Lists')
);
const DocumentationListCreate = loadable(
  () => import('./routes/documentation/ListCreate')
);
const DocumentationListsIndex = loadable(
  () => import('./routes/documentation/ListsIndex')
);
const DocumentationListView = loadable(
  () => import('./routes/documentation/ListView')
);
const DocumentationListSearch = loadable(
  () => import('./routes/documentation/ListSearch')
);
const DocumentationListStats = loadable(
  () => import('./routes/documentation/ListStats')
);
const DocumentationListEvents = loadable(
  () => import('./routes/documentation/ListEvents')
);
const DocumentationListEvent = loadable(
  () => import('./routes/documentation/ListEvent')
);
const DocumentationListUpdate = loadable(
  () => import('./routes/documentation/ListUpdate')
);
const DocumentationListDelete = loadable(
  () => import('./routes/documentation/ListDelete')
);
const DocumentationItems = loadable(
  () => import('./routes/documentation/Items')
);
const DocumentationItemCreate = loadable(
  () => import('./routes/documentation/ItemCreate')
);
const DocumentationItemsIndex = loadable(
  () => import('./routes/documentation/ItemsIndex')
);
const DocumentationItemsIndexData = loadable(
  () => import('./routes/documentation/ItemsIndexData')
);
const DocumentationItemsIndexPartialData = loadable(
  () => import('./routes/documentation/ItemsIndexPartialData')
);
const DocumentationItemView = loadable(
  () => import('./routes/documentation/ItemView')
);
const DocumentationItemViewData = loadable(
  () => import('./routes/documentation/ItemViewData')
);
const DocumentationItemViewPartialData = loadable(
  () => import('./routes/documentation/ItemViewPartialData')
);
const DocumentationItemStats = loadable(
  () => import('./routes/documentation/ItemStats')
);
const DocumentationItemEvents = loadable(
  () => import('./routes/documentation/ItemEvents')
);
const DocumentationItemEvent = loadable(
  () => import('./routes/documentation/ItemEvent')
);
const DocumentationItemUpdate = loadable(
  () => import('./routes/documentation/ItemUpdate')
);
const DocumentationItemUpdateData = loadable(
  () => import('./routes/documentation/ItemUpdateData')
);
const DocumentationItemReplaceData = loadable(
  () => import('./routes/documentation/ItemReplaceData')
);
const DocumentationItemPatchData = loadable(
  () => import('./routes/documentation/ItemPatchData')
);
const DocumentationItemUpdatePartialData = loadable(
  () => import('./routes/documentation/ItemUpdatePartialData')
);
const DocumentationItemReplacePartialData = loadable(
  () => import('./routes/documentation/ItemReplacePartialData')
);
const DocumentationItemPatchPartialData = loadable(
  () => import('./routes/documentation/ItemPatchPartialData')
);
const DocumentationItemDelete = loadable(
  () => import('./routes/documentation/ItemDelete')
);
const DocumentationItemDeletePartialData = loadable(
  () => import('./routes/documentation/ItemDeletePartialData')
);
const DocumentationIndexing = loadable(
  () => import('./routes/documentation/Indexing')
);
const DocumentationIndexes = loadable(
  () => import('./routes/documentation/Indexes')
);
const DocumentationIndexCreate = loadable(
  () => import('./routes/documentation/IndexCreate')
);
const DocumentationIndexesIndex = loadable(
  () => import('./routes/documentation/IndexesIndex')
);
const DocumentationIndexView = loadable(
  () => import('./routes/documentation/IndexView')
);
const DocumentationIndexStats = loadable(
  () => import('./routes/documentation/IndexStats')
);
const DocumentationIndexEvents = loadable(
  () => import('./routes/documentation/IndexEvents')
);
const DocumentationIndexEvent = loadable(
  () => import('./routes/documentation/IndexEvent')
);
const DocumentationIndexUpdate = loadable(
  () => import('./routes/documentation/IndexUpdate')
);
const DocumentationIndexDelete = loadable(
  () => import('./routes/documentation/IndexDelete')
);
const DocumentationErrors = loadable(
  () => import('./routes/documentation/Errors')
);
const DocumentationGenerativeAPI = loadable(
  () => import('./routes/documentation/GenerativeAPI')
);
const DocumentationVariables = loadable(
  () => import('./routes/documentation/Variables')
);
const DocumentationAuthentication = loadable(
  () => import('./routes/documentation/Authentication')
);
const DocumentationIdentities = loadable(
  () => import('./routes/documentation/Identities')
);
const DocumentationIdentitiesIndex = loadable(
  () => import('./routes/documentation/IdentitiesIndex')
);
const DocumentationIdentityCreate = loadable(
  () => import('./routes/documentation/IdentityCreate')
);
const DocumentationIdentityDelete = loadable(
  () => import('./routes/documentation/IdentityDelete')
);
const DocumentationIdentityDeleteSelf = loadable(
  () => import('./routes/documentation/IdentityDeleteSelf')
);
const DocumentationIdentityEvent = loadable(
  () => import('./routes/documentation/IdentityEvent')
);
const DocumentationIdentityEvents = loadable(
  () => import('./routes/documentation/IdentityEvents')
);
const DocumentationIdentityLogin = loadable(
  () => import('./routes/documentation/IdentityLogin')
);
const DocumentationIdentityLogout = loadable(
  () => import('./routes/documentation/IdentityLogout')
);
const DocumentationIdentityRegister = loadable(
  () => import('./routes/documentation/IdentityRegister')
);
const DocumentationIdentityStats = loadable(
  () => import('./routes/documentation/IdentityStats')
);
const DocumentationIdentityUpdate = loadable(
  () => import('./routes/documentation/IdentityUpdate')
);
const DocumentationIdentityUpdateSelf = loadable(
  () => import('./routes/documentation/IdentityUpdateSelf')
);
const DocumentationIdentityView = loadable(
  () => import('./routes/documentation/IdentityView')
);
const DocumentationIdentityViewSelf = loadable(
  () => import('./routes/documentation/IdentityViewSelf')
);
const DocumentationMessagePack = loadable(
  () => import('./routes/documentation/MessagePack')
);
const DocumentationJSON5 = loadable(
  () => import('./routes/documentation/JSON5')
);

const App = () => {
  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AuthProvider>
          <BulletinProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/action" element={<Action />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route
                path="/register"
                element={
                  <ProtectedRoute
                    requireLoggedIn={false}
                    redirectPath="/dashboard"
                  >
                    <Register />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <ProtectedRoute
                    requireLoggedIn={false}
                    redirectPath="/dashboard"
                  >
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/verify-email"
                element={
                  <ProtectedRoute
                    requireLoggedIn={false}
                    redirectPath="/dashboard"
                  >
                    <VerifyEmail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <ProtectedRoute
                    requireLoggedIn={false}
                    redirectPath="/dashboard"
                  >
                    <ForgotPassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <ProtectedRoute redirectPath="/dashboard">
                    <ResetPassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Test />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Lists />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/create"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ListEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <List />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/edit"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ListEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/items"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Items />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/items/create"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ItemEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/items/:itemId"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Item />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/items/:itemId/edit"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ItemEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/items/:itemId/versions"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ItemVersions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/items/:itemId/compare"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ItemVersionCompare />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/indexes"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Indexes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/indexes/create"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <IndexEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/indexes/:indexId"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Index />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lists/:listId/indexes/:indexId/edit"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <IndexEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tokens"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Tokens />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tokens/create"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <TokenEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tokens/:tokenId"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Token />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tokens/:tokenId/edit"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <TokenEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/identities"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Identities />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/identities/create"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <IdentityEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/identities/:identityId"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Identity />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/identities/:identityId/edit"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <IdentityEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Events />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events/:eventId"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Event />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile/edit"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ProfileEditor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subscriptions"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Subscriptions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subscriptions/:subscriptionId"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Subscription />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-subscription"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <ManageSubscription />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cancel-subscription"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <CancelSubscription />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/payment"
                element={
                  <ProtectedRoute requireLoggedIn={true} redirectPath="/login">
                    <Elements
                      stripe={stripe}
                      options={{ mode: 'setup', currency: 'gbp' }}
                    >
                      <Payment />
                    </Elements>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/:userId"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <User />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/:userId/edit"
                element={
                  <ProtectedRoute
                    requireLoggedIn={true}
                    requireAdmin={true}
                    redirectPath="/login"
                  >
                    <UserEditor />
                  </ProtectedRoute>
                }
              />
              <Route path="/user-deleted" element={<UserDeleted />} />
              <Route
                path="/docs"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationHome />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/token-permissions"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationTokenPermissions />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/event-log"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationEventLog />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/getting-started"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationGettingStarted />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/talking-to-the-api"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationTalkingToTheAPI />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/paths-and-pointers"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationPathsAndPointers />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/realtime-updates"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationRealtimeUpdates />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/using-the-sdks"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationUsingTheSDKs />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/version-control"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationVersionControl />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-schemas"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListSchemas />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/api-reference"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationAPIReference />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/lists"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationLists />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-create"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListCreate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/lists-index"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListsIndex />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-view"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListView />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-search"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListSearch />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-stats"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListStats />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-events"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListEvents />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-event"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListEvent />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-update"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListUpdate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/list-delete"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationListDelete />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/items"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItems />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-create"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemCreate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/items-index"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemsIndex />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/items-index-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemsIndexData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/items-index-partial-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemsIndexPartialData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-view"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemView />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-view-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemViewData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-view-partial-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemViewPartialData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-stats"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemStats />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-events"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemEvents />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-event"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemEvent />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-update"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemUpdate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-update-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemUpdateData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-replace-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemReplaceData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-patch-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemPatchData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-update-partial-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemUpdatePartialData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-replace-partial-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemReplacePartialData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-patch-partial-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemPatchPartialData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-delete"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemDelete />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/item-delete-partial-data"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationItemDeletePartialData />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/indexing"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexing />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/indexes"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexes />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/index-create"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexCreate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/indexes-index"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexesIndex />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/index-view"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexView />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/index-stats"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexStats />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/index-events"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexEvents />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/index-event"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexEvent />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/index-update"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexUpdate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/index-delete"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIndexDelete />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/errors"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationErrors />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/generative-api"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationGenerativeAPI />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/variables"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationVariables />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/authentication"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationAuthentication />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identities"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentities />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identities-index"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentitiesIndex />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-create"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityCreate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-delete"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityDelete />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-delete-self"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityDeleteSelf />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-event"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityEvent />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-events"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityEvents />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-login"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityLogin />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-logout"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityLogout />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-register"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityRegister />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-stats"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityStats />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-update"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityUpdate />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-update-self"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityUpdateSelf />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-view"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityView />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/identity-view-self"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationIdentityViewSelf />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/msgpack"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationMessagePack />
                  </DocumentationTokenProvider>
                }
              />
              <Route
                path="/docs/json5"
                element={
                  <DocumentationTokenProvider>
                    <DocumentationJSON5 />
                  </DocumentationTokenProvider>
                }
              />
            </Routes>
          </BulletinProvider>
        </AuthProvider>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
