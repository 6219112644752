import { faSquareJs } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleRight,
  faBomb,
  faBookOpen,
  faBox,
  faBoxesStacked,
  faBrain,
  faCalendarCheck,
  faChartSimple,
  faCircleExclamation,
  faClockRotateLeft,
  faCompress,
  faDeleteLeft,
  faDolly,
  faDoorClosed,
  faDoorOpen,
  faEdit,
  faFile,
  faFileCircleCheck,
  faFileCode,
  faFilePen,
  faFilter,
  faFolder,
  faFolderPlus,
  faGaugeHigh,
  faGear,
  faGlobe,
  faHandSparkles,
  faIdCard,
  faListUl,
  faLockOpen,
  faMagicWandSparkles,
  faMagnifyingGlass,
  faMemory,
  faMobileScreenButton,
  faPassport,
  faPlug,
  faPlus,
  faPuzzlePiece,
  faQuoteLeft,
  faRocket,
  faServer,
  faSignature,
  faSortAlphaAsc,
  faSquarePlus,
  faSquareRootVariable,
  faStopwatch,
  faTags,
  faTerminal,
  faTimeline,
  faToolbox,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { DocumentationContents } from '../types';

export const documentationContents: DocumentationContents = [
  {
    title: 'Home',
    icon: faBookOpen,
    url: '',
    contents: [
      {
        title: 'Getting started',
        icon: faRocket,
        url: '/getting-started',
        contents: [
          {
            title: 'Create a list',
            icon: faFolderPlus,
            url: '/getting-started#step-1-create-a-list',
          },
          {
            title: 'Add some items',
            icon: faSquarePlus,
            url: '/getting-started#step-2-add-some-items',
          },
          {
            title: 'Fetch all items',
            icon: faBoxesStacked,
            url: '/getting-started#step-3-fetch-all-items',
          },
          {
            title: 'Fetch an item',
            icon: faBox,
            url: '/getting-started#step-4-fetch-an-item',
          },
          {
            title: 'Update an item',
            icon: faDolly,
            url: '/getting-started#step-5-update-an-item',
          },
        ],
      },
      {
        title: 'Talking to the API',
        icon: faPlug,
        url: '/talking-to-the-api',
        contents: [
          {
            title: 'cURL',
            icon: faTerminal,
            url: '/talking-to-the-api#curl',
          },
          {
            title: 'Node.js',
            icon: faSquareJs,
            url: '/talking-to-the-api#node-fetch-and-axios',
          },
          {
            title: 'HTTP methods',
            icon: faGlobe,
            url: '/talking-to-the-api#http-methods',
          },
          {
            title: 'Rate limits',
            icon: faGaugeHigh,
            url: '/talking-to-the-api#rate-limits',
          },
          {
            title: 'Caching',
            icon: faMemory,
            url: '/talking-to-the-api#caching',
          },
        ],
      },
      {
        title: 'List schemas',
        icon: faFileCircleCheck,
        url: '/list-schemas',
      },
      {
        title: 'Indexing lists',
        icon: faTags,
        url: '/indexing',
        contents: [
          {
            title: 'Creating an index',
            icon: faPlus,
            url: '/indexing#creating-an-index',
          },
          {
            title: 'Alias indexes',
            icon: faSignature,
            url: '/indexing#alias-indexes',
          },
          {
            title: 'Sorting lists',
            icon: faSortAlphaAsc,
            url: '/indexing#sorting-lists',
          },
          {
            title: 'Filtering items',
            icon: faFilter,
            url: '/indexing#filtering-items',
          },
          {
            title: 'Searching lists',
            icon: faMagnifyingGlass,
            url: '/indexing#searching-lists',
          },
        ],
      },
      {
        title: 'Paths and pointers',
        icon: faAngleRight,
        url: '/paths-and-pointers',
        contents: [
          {
            title: 'Fetch partial data',
            icon: faPuzzlePiece,
            url: '/paths-and-pointers#fetch-partial-data',
          },
          {
            title: 'Update partial data',
            icon: faEdit,
            url: '/paths-and-pointers#update-partial-data',
          },
          {
            title: 'Delete partial data',
            icon: faDeleteLeft,
            url: '/paths-and-pointers#delete-partial-data',
          },
        ],
      },
      {
        title: 'Token permissions',
        icon: faLockOpen,
        url: '/token-permissions',
      },
      {
        title: 'Authentication',
        icon: faPassport,
        url: '/authentication',
      },
      {
        title: 'Realtime updates',
        icon: faStopwatch,
        url: '/realtime-updates',
      },
      {
        title: 'Event log',
        icon: faTimeline,
        url: '/event-log',
      },
      {
        title: 'Version control',
        icon: faClockRotateLeft,
        url: '/version-control',
      },
      {
        title: 'Using the SDKs',
        icon: faToolbox,
        url: '/using-the-sdks',
        contents: [
          {
            title: 'Server',
            icon: faServer,
            url: '/using-the-sdks#server-example',
          },
          {
            title: 'Browser',
            icon: faMobileScreenButton,
            url: '/using-the-sdks#browser-example',
          },
        ],
      },
      {
        title: 'Generative API',
        icon: faBrain,
        url: '/generative-api',
        contents: [
          {
            title: 'Fetch and generate',
            icon: faHandSparkles,
            url: '/generative-api#fetch-and-generate',
          },
          {
            title: 'Create and generate',
            icon: faMagicWandSparkles,
            url: '/generative-api#create-and-generate',
          },
        ],
      },
      {
        title: 'Variables',
        icon: faSquareRootVariable,
        url: '/variables',
      },
      {
        title: 'JSON5',
        icon: faQuoteLeft,
        url: '/json5',
      },
      {
        title: 'MessagePack',
        icon: faCompress,
        url: '/msgpack',
      },
    ],
  },
  {
    title: 'API reference',
    icon: faGear,
    url: '/api-reference',
    contents: [
      {
        title: 'Lists',
        icon: faFolder,
        url: '/lists',
        contents: [
          {
            title: 'Create a list',
            icon: faPlus,
            url: '/list-create',
          },
          {
            title: 'Fetch all lists',
            icon: faListUl,
            url: '/lists-index',
          },
          {
            title: 'View a list',
            icon: faFile,
            url: '/list-view',
          },
          {
            title: 'Search a list',
            icon: faMagnifyingGlass,
            url: '/list-search',
          },
          {
            title: 'Fetch list stats',
            icon: faChartSimple,
            url: '/list-stats',
          },
          {
            title: 'Fetch list events',
            icon: faClockRotateLeft,
            url: '/list-events',
          },
          {
            title: 'View a list event',
            icon: faCalendarCheck,
            url: '/list-event',
          },
          {
            title: 'Update a list',
            icon: faFilePen,
            url: '/list-update',
          },
          {
            title: 'Delete a list',
            icon: faTrash,
            url: '/list-delete',
          },
        ],
      },
      {
        title: 'Items',
        icon: faFileCode,
        url: '/items',
        contents: [
          {
            title: 'Create an item',
            icon: faPlus,
            url: '/item-create',
          },
          {
            title: 'Fetch all items',
            icon: faListUl,
            url: '/items-index',
          },
          {
            title: 'Fetch all item data',
            icon: faListUl,
            url: '/items-index-data',
          },
          {
            title: 'Fetch partial item data',
            icon: faListUl,
            url: '/items-index-partial-data',
          },
          {
            title: 'View an item',
            icon: faFile,
            url: '/item-view',
          },
          {
            title: 'View item data',
            icon: faFile,
            url: '/item-view-data',
          },
          {
            title: 'View partial item data',
            icon: faFile,
            url: '/item-view-partial-data',
          },
          {
            title: 'Fetch item stats',
            icon: faChartSimple,
            url: '/item-stats',
          },
          {
            title: 'Fetch item events',
            icon: faClockRotateLeft,
            url: '/item-events',
          },
          {
            title: 'View an item event',
            icon: faCalendarCheck,
            url: '/item-event',
          },
          {
            title: 'Update an item',
            icon: faFilePen,
            url: '/item-update',
          },
          {
            title: 'Update item data',
            icon: faFilePen,
            url: '/item-update-data',
          },
          {
            title: 'Replace item data',
            icon: faFilePen,
            url: '/item-replace-data',
          },
          {
            title: 'Patch item data',
            icon: faFilePen,
            url: '/item-patch-data',
          },
          {
            title: 'Update partial item data',
            icon: faFilePen,
            url: '/item-update-partial-data',
          },
          {
            title: 'Replace partial item data',
            icon: faFilePen,
            url: '/item-replace-partial-data',
          },
          {
            title: 'Patch partial item data',
            icon: faFilePen,
            url: '/item-patch-partial-data',
          },
          {
            title: 'Delete an item',
            icon: faTrash,
            url: '/item-delete',
          },
          {
            title: 'Delete partial item data',
            icon: faTrash,
            url: '/item-delete-partial-data',
          },
        ],
      },
      {
        title: 'Indexes',
        icon: faTags,
        url: '/indexes',
        contents: [
          {
            title: 'Create an index',
            icon: faPlus,
            url: '/index-create',
          },
          {
            title: 'Fetch all indexes',
            icon: faListUl,
            url: '/indexes-index',
          },
          {
            title: 'View an index',
            icon: faFile,
            url: '/index-view',
          },
          {
            title: 'Fetch index stats',
            icon: faChartSimple,
            url: '/index-stats',
          },
          {
            title: 'Fetch index events',
            icon: faClockRotateLeft,
            url: '/index-events',
          },
          {
            title: 'View an index event',
            icon: faCalendarCheck,
            url: '/index-event',
          },
          {
            title: 'Update an index',
            icon: faFilePen,
            url: '/index-update',
          },
          {
            title: 'Delete an index',
            icon: faTrash,
            url: '/index-delete',
          },
        ],
      },
      {
        title: 'Identities',
        icon: faIdCard,
        url: '/identities',
        contents: [
          {
            title: 'Create an identity',
            icon: faPlus,
            url: '/identity-create',
          },
          {
            title: 'Fetch all identities',
            icon: faListUl,
            url: '/identities-index',
          },
          {
            title: 'View an identity',
            icon: faFile,
            url: '/identity-view',
          },
          {
            title: 'Fetch identity stats',
            icon: faChartSimple,
            url: '/identity-stats',
          },
          {
            title: 'Fetch identity events',
            icon: faClockRotateLeft,
            url: '/identity-events',
          },
          {
            title: 'View an identity event',
            icon: faCalendarCheck,
            url: '/identity-event',
          },
          {
            title: 'Update an identity',
            icon: faFilePen,
            url: '/identity-update',
          },
          {
            title: 'Delete an identity',
            icon: faTrash,
            url: '/identity-delete',
          },
          {
            title: 'Register an identity',
            icon: faPassport,
            url: '/identity-register',
          },
          {
            title: 'Login an identity',
            icon: faDoorOpen,
            url: '/identity-login',
          },
          {
            title: 'Logout an identity',
            icon: faDoorClosed,
            url: '/identity-logout',
          },
          {
            title: 'Fetch the current identity',
            icon: faFile,
            url: '/identity-view-self',
          },
          {
            title: 'Update the current identity',
            icon: faFilePen,
            url: '/identity-update-self',
          },
          {
            title: 'Delete the current identity',
            icon: faTrash,
            url: '/identity-delete-self',
          },
        ],
      },
      {
        title: 'Errors',
        icon: faCircleExclamation,
        url: '/errors',
        contents: [
          {
            title: 'Error object',
            icon: faBomb,
            url: '/errors#error-object',
          },
          {
            title: 'Error codes',
            icon: faTriangleExclamation,
            url: '/errors#error-codes',
          },
        ],
      },
    ],
  },
];
