import { useCallback, useState } from 'react';
import config from '../config';
import {
  Error as ApiError,
  Index,
  IndexMinimal,
  IndexOrderField,
  IndexStats,
  PaginatedRequest,
  PaginatedResponse,
} from '../types';
import pluckParams from '../utilities/pluck-params';
import prepareHeaders from '../utilities/prepare-headers';
import { useAuth } from './auth';

const useIndexes = () => {
  const { token } = useAuth();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: Error) => {
    setPending(false);

    if (error.name === 'AbortError') {
      return;
    }

    setError(error);
  };

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  const index = useCallback(
    async (
      listId: string,
      options?: PaginatedRequest<IndexOrderField> & {
        activated?: boolean;
      },
      signal?: AbortSignal
    ): Promise<PaginatedResponse<Index> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/indexes?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
          ...pluckParams(actualOptions, 'activated'),
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<Index>;
        })
        .catch(handleError);
    },
    [token]
  );

  const show = useCallback(
    async (
      listId: string,
      indexId: string,
      signal?: AbortSignal
    ): Promise<Index | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}/indexes/${indexId}`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Index;
        })
        .catch(handleError);
    },
    [token]
  );

  const stats = useCallback(
    async (
      listId: string,
      indexId: string,
      signal?: AbortSignal
    ): Promise<IndexStats | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/indexes/${indexId}/stats`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as IndexStats;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexEvents = useCallback(
    async (
      listId: string,
      indexId: string,
      options?: PaginatedRequest<'createdAt'>,
      signal?: AbortSignal
    ): Promise<PaginatedResponse<Event> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${
          config.api.url
        }/lists/${listId}/indexes/${indexId}/events?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<Event>;
        })
        .catch(handleError);
    },
    [token]
  );

  const showEvent = useCallback(
    async (
      listId: string,
      indexId: string,
      eventId: string,
      signal?: AbortSignal
    ): Promise<Event | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/indexes/${indexId}/events/${eventId}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Event;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexAllMinimal = useCallback(
    async (signal?: AbortSignal): Promise<IndexMinimal[] | void> => {
      setPending(true);

      return fetch(`${config.api.url}/dashboard/indexes`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return ((await response.json()).data ?? []) as IndexMinimal[];
        })
        .catch(handleError);
    },
    [token]
  );

  const checkPathName = useCallback(
    async (
      listId: string,
      pathName: string,
      signal?: AbortSignal
    ): Promise<boolean | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/dashboard/indexes?${new URLSearchParams({
          listId,
          pathName,
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          const responseData = await response.json();
          if (responseData) {
            // Path name is already in use
            if (
              (responseData.data?.length ?? 0) > 0 &&
              responseData.data.find(
                (datum: IndexMinimal) => datum.pathName === pathName
              )
            ) {
              return false;
            }

            // Path name is reserved
            if (
              responseData.reservedPathNames &&
              responseData.reservedPathNames
                .map((p: string) => p.toLowerCase().trim())
                .includes(pathName.toLowerCase().trim())
            ) {
              return false;
            }
          }

          return true;
        })
        .catch(handleError);
    },
    [token]
  );

  const create = useCallback(
    async (listId: string, data: Partial<Index>): Promise<Index | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}/indexes`, {
        method: 'POST',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Index;
        })
        .catch(handleError);
    },
    [token]
  );

  const update = useCallback(
    async (
      listId: string,
      indexId: string,
      data: Partial<Index>
    ): Promise<Index | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}/indexes/${indexId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Index;
        })
        .catch(handleError);
    },
    [token]
  );

  const activate = useCallback(
    async (listId: string, indexId: string): Promise<Index | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}/indexes/${indexId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: true }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Index;
        })
        .catch(handleError);
    },
    [token]
  );

  const deactivate = useCallback(
    async (listId: string, indexId: string): Promise<Index | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}/indexes/${indexId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: false }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Index;
        })
        .catch(handleError);
    },
    [token]
  );

  const delete_ = useCallback(
    async (listId: string, indexId: string): Promise<void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}/indexes/${indexId}`, {
        method: 'DELETE',
        headers: prepareHeaders(token),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }
        })
        .catch(handleError);
    },
    [token]
  );

  return {
    pending,
    error,
    resetError,
    index,
    show,
    stats,
    indexEvents,
    showEvent,
    indexAllMinimal,
    checkPathName,
    create,
    update,
    activate,
    deactivate,
    delete: delete_,
  };
};

export default useIndexes;
